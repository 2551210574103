<template>

  <div class="">
    <div v-if="scriptsLoaded" id="chart">
      <apexcharts
      :height="550" ref="mixed" type="area" :options="chartOptions" :series="series"
      ></apexcharts>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: "AreaGraph",
  props:["graphData",'leads'],
    data: function() {
    return {
      scriptsLoaded:false,
      series:this.graphData,
      chartOptions: {
       colors: ['#ffa201', '#96DB99', '#719DFC'],
        chart: {
          id: "Quiz-Statistics",
          type: "area",
          stacked:false,
          events: {
                selection: function (chart, e) {
                  e.xaxis.min
                 
                }
              },
        },
        noData: {
          text: "No Data found",
        },
        dataLabels: {
          enabled: false,
        },
        //  colors: ["#48cae4"],
        stroke: {
          curve: "smooth",
          // width: 3,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        yaxis: {
          min: 0,
        //   max: 1500,
        tickAmount: 8,
          lines: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "15px",
              colors: "#B2B2B2",
            },
          },
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        grid: {
          row: {
         
            opacity: 0.1,
          },
        },
        fill: {
        type: 'gradient',
              gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
              }
        },
        legend: {
              position: 'top',
              horizontalAlign: 'left'
            },
      },
    };
  },
  methods:{
    downloadSVG() {
      // eslint-disable-next-line no-undef
     
     console.log(this.$refs.mixed)
      this.$refs.mixed.dataURI().then(({ imgURI, blob }) => {
        console.log(imgURI,blob)
        const a = document.createElement('a');
        a.href = imgURI;
        a.download = 'chart.svg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
      // eslint-disable-next-line no-undef
     
    },
    downloadPNG() {
            // eslint-disable-next-line no-undef
      ApexCharts.exec('vuechart-example', 'dataURI').then((uri) => {
        const a = document.createElement('a');
        a.href = uri.png;
        a.download = 'chart.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    downloadCSV() {
      const chart = this.$refs.chart.chart;
      const csv = chart.data.exportToCSV();
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'chart.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    initApexCharts() {
      this.loadScript('https://cdn.jsdelivr.net/npm/apexcharts')
        .then(() => {
          return this.loadScript('https://cdn.jsdelivr.net/npm/vue-apexcharts');
        })
        .then(() => {
         

          // Registering ApexCharts as a global component
          Vue.component('apexcharts', window.VueApexCharts);
          this.scriptsLoaded = true;
          return 
          
        })
        .catch(err => {
          throw err
        });
    },
  },
  mounted() {
    this.initApexCharts();
  },

};
</script>

<style></style>